import { Component, Input, OnInit } from '@angular/core';
import { ApService } from 'src/app/shared/services/ap.service';
import { TemplateService } from 'src/app/shared/services/template.service';
import { VceVhubService } from 'src/app/shared/services/vce-vhub.service';
import { FormatterHelper } from 'src/app/shared/helper/formatter-helper';
import { Timezone } from "src/app/shared/model/timezone";
@Component({
  selector: 'app-device-monitor-system',
  templateUrl: './device-monitor-system.component.html',
  styleUrls: ['./device-monitor-system.component.scss']
})
export class DeviceMonitorSystemComponent implements OnInit {
  _device: any;
  statusType: any;
  mac: any;
  machineId: any;
  cpuCount: any;
  nicCount: any;
  firmware: any;
  system: any;
  uptime: string;
  totalBytes: string;
  freeBytes: string;
  sharedBytes: string;
  bufferBytes: string;
  availableBytes: string;
  cachedBytes: string;
  usedBytes: string;
  memoryUsageBytes: string;
  memoryUsagePercentage: string;
  load_avg: any = [];
  free: number;
  shared: number;
  buffer: number;
  available: number;
  cached: number;
  used: number;
  connection_count: any;
  connection_max: any;
  dhcp6_leases: any = [];
  dhcp_leases: any = [];
  dhcp_static_leases: any = [];
  assoc_list: any = [];
  timezone: any;
  logging: any;
  ras: any;
  ether_ports: any;
  lan_info: any;
  wan_info: any;
  lan_ports: any;
  interfaces: any = [];

  timezoneArray = Timezone;
  @Input() set device(obj) {
    if (!obj || !obj[0]) {
      return;
    }
    this._device = obj[0];
    this.loadData()
  }

  constructor(
    public teamService: TemplateService,
    public vceVhubService: VceVhubService,
    public apService: ApService
  ) { }

  ngOnInit(): void {
  }

  loadData() {
    this.ras = [];
    this.vceVhubService.getRas(this._device.mac).subscribe(res => {
      this.ras = res;
    });
    if(this._device.statistics){ //system
      this.statusType = this._device.statistics.statusType;
      this.mac = this._device.statistics.mac;
      this.machineId = this._device.statistics?.machineId !== undefined ? this._device.statistics.machineId : "";
      this.cpuCount = this._device.statistics?.cpuCount !== undefined ? this._device.statistics.cpuCount : "" ;
      this.nicCount = this._device.statistics?.nicCount !== undefined ? this._device.statistics.nicCount : "" ;
      this.firmware = this._device.statistics.firmware?.currentVersion !== undefined ? this._device.statistics.firmware.currentVersion : "" ;
      this.system = this._device.statistics?.system && !this._device.statistics?.system.length ? this._device.statistics.system : [];
      this.uptime = FormatterHelper.uptimeToStr(this.system.uptime);
      this.memoryUsagePercentage = (100*(this._device.statistics.memory.free / this._device.statistics.memory.total)).toFixed(2);
      this.load_avg = this._device.statistics.avg_load[1]
      if(this._device.statistics.memory !== undefined){ //memory
        this.totalBytes = FormatterHelper.formatBytes(this._device.statistics.memory.total);
        this.freeBytes = FormatterHelper.formatBytes(this._device.statistics.memory.free);
        this.sharedBytes = FormatterHelper.formatBytes(this._device.statistics.memory.shared);
        this.bufferBytes = FormatterHelper.formatBytes(this._device.statistics.memory.buffered);
        this.availableBytes = FormatterHelper.formatBytes(this._device.statistics.memory.available);
        this.cachedBytes = FormatterHelper.formatBytes(this._device.statistics.memory.cached);
        this.usedBytes = FormatterHelper.formatBytes(this._device.statistics.memory.total - this._device.statistics.memory.free);        
        this.memoryUsageBytes = FormatterHelper.formatBytes(this._device.statistics.memory.total - this._device.statistics.memory.available);
        this.free = (100/(this._device.statistics.memory.total/ this._device.statistics.memory.free));
        this.shared = (100/(this._device.statistics.memory.total/ this._device.statistics.memory.shared));
        this.buffer = (100/(this._device.statistics.memory.total/ this._device.statistics.memory.buffered));
        this.available = (100/(this._device.statistics.memory.total/ this._device.statistics.memory.available));
        this.cached = (100/(this._device.statistics.memory.total/ this._device.statistics.memory.cached));
        this.used = (100/(this._device.statistics.memory.total/ (this._device.statistics.memory.total - this._device.statistics.memory.free)));
      }

      this.connection_count = this._device.statistics.connection_count !== undefined ? this._device.statistics.connection_count : "";
      this.connection_max = this._device.statistics.connection_max !== undefined ? this._device.statistics.connection_max : "";

      this.dhcp6_leases = this._device.statistics.dhcp6_leases && this._device.statistics.dhcp6_leases.length > 0 ? this._device.statistics.dhcp6_leases : [];
      this.dhcp_leases = this._device.statistics.dhcp_leases && this._device.statistics.dhcp_leases.length > 0? this._device.statistics.dhcp_leases : [];
      this.dhcp_static_leases = this._device.statistics.dhcp_static_lease && this._device.statistics.static_leases && this._device.statistics.static_leases.length > 0? this._device.statistics.static_leases : [];
      
      this.assoc_list = this._device.statistics.assoc_list && !this._device.statistics.assoc_list.length ? this._device.statistics.assoc_list : [];

      var _timezone =  this._device.statistics.timezone !== undefined ? this._device.statistics.timezone : "";
      var systemTimeZone = this.timezoneArray.filter(x=> x['tz-posix'] == _timezone);      
      this.timezone = systemTimeZone[0]['name'] + ', ' + systemTimeZone[0]['tz-posix']

      this.logging = this._device.statistics.logging && !this._device.statistics.logging.length ? this._device.statistics.logging : [];
      this.lan_info = this._device.statistics?.vlan_info?.lan_vlan.filter(x=> ["lan","lan2","lan3","guest"].includes(x.network));
      this.wan_info = this._device.statistics?.vlan_info?.wan_vlan;

      let ports = []
      ports["lan"] = this._device.statistics?.vlan_info?.lan_ports ? this._device.statistics?.vlan_info?.lan_ports: []
      ports["lan2"] = this._device.statistics?.vlan_info?.lan2_ports ? this._device.statistics?.vlan_info?.lan2_ports: []
      ports["lan3"] = this._device.statistics?.vlan_info?.lan3_ports ? this._device.statistics?.vlan_info?.lan3_ports: []
      
      this.lan_ports = ports
      let _ether_ports = this._device.statistics.ether_ports && !this._device.statistics.ether_ports.length ? this._device.statistics.ether_ports : [];      
      let ether_details = []
      ether_details["data"] = []
      for (let index = 0; index < _ether_ports.ether_ports.length; index++) {
        ether_details["data"][_ether_ports.ether_ports[index]["label"]] = _ether_ports.ether_ports[index];
      }
      ether_details["length"] = _ether_ports.ether_ports.length
      ether_details["model"] = _ether_ports["model"]
      ether_details["mode"] = _ether_ports["mode"]
      ether_details["version"] = _ether_ports["version"]
      this.ether_ports = ether_details
      this.loadInterface()
    }
  }
  loadInterface(){
    this.interfaces = [];
    if(this._device.statistics && this._device.statistics.interfaces && this._device.statistics.interfaces.length){
      let interfaces_tmp = this._device.statistics.interfaces;
      interfaces_tmp.map(ifc => {
        if (ifc.zone && ifc.zone != "") {
          // AP: Only accept new payload. Old payload is lacking data.
          this.interfaces.push(ifc);
        }
      });
      this.interfaces.map(ifc => {
        if (ifc.up && ifc.up == "true") {
          ifc.status = "Up";
        } else {
          ifc.status = "Down";
        }
        ifc.uptime_str = FormatterHelper.uptimeToStr(ifc.uptime ?? 0);
        ifc.tx = FormatterHelper.formatBytes(ifc.rx_bytes ?? 0);
        ifc.rx = FormatterHelper.formatBytes(ifc.rx_bytes ?? 0);
        ifc.rxpkt = FormatterHelper.formatNumber(ifc.rx_pkts ?? 0, 0);
        ifc.txpkt = FormatterHelper.formatNumber(ifc.tx_pkts ?? 0, 0);
      });
    }
  }
  displayDHCPs(val){
    if(val == 0){
      return "Disabled"
    }
    return "Enabled"
  }
  displayMode(val){
    if(val == "port_based_lans"){
      return "Port Based LANs"
    } else if(val == "trunk_port_123") {
       return "Trunk Port P1, P2, P3"
    }
    return ""
  }
  displayPortSpeed(val){
    if(val == "autoneg"){
      return "Auto Negotiation"
    } else if(val == "10_half") {
       return "10baseT/Half"
    } else if(val == "10_full") {
      return "10baseT/Full"
    } else if(val == "100_half") {
    return "100baseT/Half"
    } else if(val == "100_full") {
      return "100baseT/Full"
   }
    return ""
  }
  displayType(val){
    if(val.includes("core")){
      return "Default/Untagged"
    }
    return ""
  }
  getObjectKeys(myObject): string[] {
    return Object.keys(myObject);
  }
  getObjectValues(myObject): string[] {
    return Object.values(myObject);
  }
  timestampByHrs(ts){
    var getSecs = (ts/1000)    
    var getMins = ((getSecs) / 60) * 100
    var getHours = ((getSecs) / (60 * 60)) * 1000
    
   return getHours.toFixed(0) + "h " + getMins.toFixed(0) +  "m " + getSecs.toFixed(0) + "s"
  }

}
