import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { LinkTypes, TeamLoadBalancerTypes, LinkQualityTypes } from 'src/app/shared/model/template';
import { ApService } from 'src/app/shared/services/ap.service';
import { TemplateService } from 'src/app/shared/services/template.service';
import { Timezone } from 'src/app/shared/model/timezone'
@Component({
  selector: 'app-device-monitor-team1',
  templateUrl: './device-monitor-team1.component.html',
  styleUrls: ['./device-monitor-team1.component.scss']
})
export class DeviceMonitorTeam1Component implements OnInit, OnDestroy {
  BalancerArray: any = TeamLoadBalancerTypes;
  label = "";
  checked = false
  hideToggle = false;
  _device: any;
  teamLink: any;
  linkFlaps: any = [];

  linkStates: any = [];
  linkStatesMax = 0;
  linkFlapMax = 0;
  timezoneArray = Timezone;

  @Input() set device(obj) {
    if (!obj || !obj[0]) {
      return;
    }
    this._device = obj[0];
    this.loadData()
  }

  teamTpl: any;
  linkTpls: any = [];

  linkLength: any = 3;

  teamTplOnPortal: any;
  linkTplsOnPortal: any = [];

  private loadDataSubscription: Subscription;

  constructor(
    public teamService: TemplateService,
    public apService: ApService
  ) { }

  ngOnInit(): void {
  }

  loadData() {
    if (this._device.templateConfig && this._device.templateConfig.team && (this._device.templateConfig.team.template_enabled == false)) {
      if (this._device.templateConfig.team.config) {
        this.label = this._device.templateConfig.team.name ? this._device.templateConfig.team.name : "Device Configuration" + " (Template-Disabled)"
        if (this._device.templateConfig.team.config) {
          this.teamTpl = this._device.templateConfig.team.config.team1;
          this.linkTpls = this._device.templateConfig.team.config.team1.links ? this._device.templateConfig.team.config.team1.links : [];
        }
      } else if (this._device.team) {
        this.loadDataSubscription = this.teamService.getTeam(this._device.team).subscribe(res => {
          if (res && res[0]) {
            res = res[0];
            this.teamTpl = res.config.team1;
            this.linkTpls = res.config.team1.links ? res.config.team1.links : [];
            this.label = res.name + ' (Template-Disabled)'
          }
        })
      } else {
        this.label = this._device.templateConfig['team'].template_name !== null ? (this._device.templateConfig['team'].template_enabled == false ? this._device.templateConfig['team'].template_name + " (Template-Disabled)" : this._device.templateConfig['team'].template_name + " (Template-Enabled)") : "Device (Configuration)";
        this.teamTpl = this._device.templateConfig.team.config.team1;
        this.linkTpls = this._device.templateConfig.team.config.team1.links ? this._device.templateConfig.team.config.team1.links : [];
        this.hideToggle = true;
      }
      this.linkLength = Math.max(this.linkTpls.length, 3);
    } else if (this._device.templateConfig && this._device.templateConfig.team && this._device.templateConfig.team.template_enabled && this._device.team) {
      this.checked = true;
      this.loadTpl(this._device.team);
      if (this._device.templateConfig.team.config) {
        this.teamTpl = this._device.templateConfig.team.config.team1;
        this.linkTpls = this._device.templateConfig.team.config.team1.links ? this._device.templateConfig.team.config.team1.links : [];
      }
    } else if (this._device && this._device && this._device.team) {
      this.loadTpl(this._device.team);
      if (this._device.templateConfig.team.config) {
        this.teamTpl = this._device.templateConfig.team.config.team1;
        this.linkTpls = this._device.templateConfig.team.config.team1.links ? this._device.templateConfig.team.config.team1.links : [];
      }
      this.checked = true;
    } else if (this._device.templateConfig && this._device.templateConfig.team) {
      this.label = this._device.templateConfig['team'].template_name !== null ? (this._device.templateConfig['team'].template_enabled == false ? this._device.templateConfig['team'].template_name + " (Template-Disabled)" : this._device.templateConfig['team'].template_name + " (Template-Enabled)") : "Device (Configuration)";
      if (this._device.templateConfig.team.config) {
        this.teamTpl = this._device.templateConfig.team.config.team1;
        this.linkTpls = this._device.templateConfig.team.config.team1.links ? this._device.templateConfig.team.config.team1.links : [];
      }
      this.hideToggle = true;
      this.linkLength = Math.max(this.linkTpls.length, 3);
    }
    this.formatlinkTpls();
    if (this._device.statistics && this._device.statistics.teams.team1) {
      this.linkStates = [];
      this.linkFlaps = [];
      this.teamLink = this._device.statistics.teams.team1.links;
      let joinStateTs = [];
      var timezone =  this._device.statistics.timezone !== undefined ? this._device.statistics.timezone : "";
      var systemTimeZone = this.timezoneArray.filter(x=> x['tz-posix'] == timezone);

      this.teamLink.forEach(team => {
        if (team.linkstate_hist) {
          team.linkstate_hist.sort((a,b) =>  parseInt(a.ts) < parseInt(b.ts) ? 1 : parseInt(a.ts) > parseInt(b.ts) ? -1 : 0);
          team.linkstate_hist.forEach(state => {
            if (joinStateTs.indexOf(parseInt(state.ts)) == -1) {
              joinStateTs.push(parseInt(state.ts));
            }
          });
        }
      });
      joinStateTs.sort((a,b) =>  a < b ? 1 : a > b ? -1 : 0);
      this.teamLink.forEach(team => {
        if (team.linkflap_hist) {
          let flapArr = [];
          team.linkflap_hist.split(",").forEach(flap => {
           
            flapArr.push(moment.unix(flap).tz(systemTimeZone && systemTimeZone.length > 0 ? systemTimeZone[0]['tz'] : 'America/New_York').format('ddd MMM D HH:mm:ss zz YYYY'));
          });

          flapArr = flapArr.sort((a,b) =>  new Date(a) < new Date(b) ? 1 : new Date(a) > new Date(b) ? -1 : 0)
          this.linkFlaps.push(flapArr);
        }
        if (team.linkstate_hist && joinStateTs.length) {
          let linkState = [];
          team.linkstate_hist.forEach(state => {
            let state_text = "";
            if (state.state == 0) {
              state_text = " (D)";
            }
            if (state.state == 1) {
              state_text = " (U)";
            }
            if (state.state == 2) {
              state_text = " (F)";
            }
            if (state.state == 3) {
              state_text = " (B)";
            }
            if (state.state == 101) {
              state_text = " (1)";
            }
            if (state.state == 102) {
              state_text = " (2)";
            }
            let tindex = joinStateTs.indexOf(parseInt(state.ts));

            if (tindex > linkState.length) {
              let textra = tindex - linkState.length;
              for(let i = 0; i < textra; i++) {
                linkState.push(" ");
              }
            }
            linkState.push(moment.unix(state.ts).tz(systemTimeZone && systemTimeZone.length > 0 ? systemTimeZone[0]['tz'] : 'America/New_York').format('ddd MMM D HH:mm:ss zz YYYY') + state_text);
          });
          this.linkStates.push(linkState);
        }

      });
      if (this.linkStates.length > 0) {
        this.linkStatesMax = this.linkStates?.reduce((a,b)=> { 
          return a.length > b.length? a : b; 
        }).length;
      }      
      else if(this.linkFlaps.length > 0){
        this.linkFlapMax = this.linkFlaps?.reduce((a,b)=> { 
          return a.length > b.length? a : b; 
        }).length;
      }
    }
  }

  formatlinkTpls(){
    if(this.linkTpls){
      this.linkTpls.forEach(lnktpl=> {
        lnktpl.link_url = lnktpl.link_url.split(' ');
        lnktpl.link_ip = lnktpl.link_ip.split(' ');
        lnktpl.link_ip_port = lnktpl.link_ip_port.split(' ');
        lnktpl.link_net = lnktpl.link_net.split(' ');
        lnktpl.link_net_port = lnktpl.link_net_port.split(' ');
        lnktpl.link_src_ip = lnktpl.link_src_ip.split(' ');
        lnktpl.link_src_ip_port = lnktpl.link_src_ip_port.split(' ');
        lnktpl.link_src_net = lnktpl.link_src_net.split(' ');
        lnktpl.link_src_net_port = lnktpl.link_src_net_port.split(' ');
      })
    }
    if(this.linkTplsOnPortal){
        this.linkTplsOnPortal.forEach(lnktpl=> {
        lnktpl.link_url = lnktpl.link_url.split(' ');
        lnktpl.link_ip = lnktpl.link_ip.split(' ');
        lnktpl.link_ip_port = lnktpl.link_ip_port.split(' ');
        lnktpl.link_net = lnktpl.link_net.split(' ');
        lnktpl.link_net_port = lnktpl.link_net_port.split(' ');
        lnktpl.link_src_ip = lnktpl.link_src_ip.split(' ');
        lnktpl.link_src_ip_port = lnktpl.link_src_ip_port.split(' ');
        lnktpl.link_src_net = lnktpl.link_src_net.split(' ');
        lnktpl.link_src_net_port = lnktpl.link_src_net_port.split(' ');
      })
    }
  }

  loadTpl(teamId) {
    this.teamService.getTeam(teamId).subscribe(res => {
      if (res && res[0]) {
        res = res[0];
        this.label = res.name + (this._device.templateConfig.team && this._device.templateConfig.team.template_enabled ? " (Template-Enabled)" : this._device.templateConfig.team && this._device.templateConfig.team.template_enabled == false ? " (Template-Disabled)" : " (Template)");
        try {
          this.teamTplOnPortal = res.config.team1;
          this.linkTplsOnPortal = res.config.team1.links ? res.config.team1.links : [];
          this.linkLength = Math.max(this.linkTpls.length, 3, this.linkTplsOnPortal.length);
        } catch (e) {

        }
      }
    });
  }

  onTplToggle(e) {
    this.checked = e.checked
    this.apService.toggleTemplate(this._device._id, "team", this.checked).subscribe(res => {
      if (this._device.templateConfig && this._device.templateConfig.team) {
        this._device.templateConfig.team.template_enabled = e.checked;
      } else {
        this._device.templateConfig.team = {
          template_enabled: e.checked
        }
      }
      this.loadData()
    })
  }

  ngOnDestroy(): void {
    if (this.loadDataSubscription) {
      this.loadDataSubscription.unsubscribe();
    }
  }

  getBalancerLabel(tpl) {
    if (tpl && tpl.balancer) {
      return this.BalancerArray.filter(b => b.value == tpl.balancer)[0].name;
    }
  }

  getLinkTypeLabel(tpl) {
    if (tpl && tpl.link_type) {
      return LinkTypes.filter(l => l.value == tpl.link_type)[0].name
    }
  }

  getLinkQualityLabel(tpl) {
    if (tpl && tpl.monitor_profile) {
      return LinkQualityTypes.filter(l => l.value == tpl.monitor_profile)[0].name
    }
  }

  isDifferent(obj1, obj2) {
    if (this.checked && (obj1 || obj2) && (obj1 != obj2)) {
      return true;
    } else {
      return false;
    }
  }

}
